import { useState, useEffect } from "react"
import queryString from "query-string"

const useSelectedVariant = (variants, location) => {
  const [selectedVariant, setSelectedVariant] = useState(null)
  const { variant } = queryString.parse(location.search)

  useEffect(() => {
    if (!variants.length) return null

    if (variant) {
      setSelectedVariant(
        variants.find((bv) => bv.slug.current === variant) ?? variants[0]
      )
    } else {
      setSelectedVariant(variants[0])
    }
  }, [variants, variant])

  return [selectedVariant, setSelectedVariant]
}

export default useSelectedVariant
