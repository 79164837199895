import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useState, useRef, useEffect } from "react"
import Slider from "react-slick"
import styled, { css } from "styled-components"

const Container = styled.div`
  display: flex;
`

const VerticalSlider = styled(Slider)`
  width: 25%;
  position: relative;

  .slick-track {
    height: 100% !important;
  }

  .slick-slide {
    border: none !important;
    cursor: pointer !important;
  }

  .slick-next {
    bottom: 7px !important;
    top: auto !important;
    left: 50% !important;
    transform: translate(-50%, 0) !important;
  }

  .slick-prev {
    bottom: auto !important;
    top: 7px !important;
    left: 50% !important;
    transform: translate(-50%, 0) !important;
  }
`

const MainSlider = styled(Slider)`
  width: 75%;

  .slick-next,
  .slick-prev {
    display: none;
    right: 0;
    left: 0;
  }
`

const SlickButton = styled.button`
  font-size: 2rem;
  color: ${(p) => p.theme.colors.black};
  z-index: 1000;

  &:hover {
    opacity: 1 !important;
  }
`

const Image = styled(GatsbyImage)`
  ${(p) =>
    p.active &&
    css`
      border: 1px solid ${p.theme.colors.black};
    `}
`

const VerticalPreviewCarousel = ({ images }) => {
  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)
  let sliderRef1 = useRef(null)
  let sliderRef2 = useRef(null)
  const [activeSlide, setActiveSlide] = useState(0)

  useEffect(() => {
    sliderRef1.slickGoTo(0)
    sliderRef2.slickGoTo(0)
  }, [images])

  useEffect(() => {
    setNav1(sliderRef1)
    setNav2(sliderRef2)
  }, [])

  const SlickButtonFix = ({
    currentSlide,
    slideCount,
    children,
    next,
    previous,
    ...props
  }) => <SlickButton {...props}>{children}</SlickButton>

  const NextArrow = <SlickButtonFix>↓</SlickButtonFix>
  const PrevArrow = <SlickButtonFix>↑</SlickButtonFix>

  return (
    <Container>
      <VerticalSlider
        asNavFor={nav1}
        ref={(slider) => (sliderRef2 = slider)}
        slidesToShow={3}
        swipeToSlide={true}
        focusOnSelect={true}
        vertical={true}
        infinite={false}
        nextArrow={React.cloneElement(NextArrow, {
          next: true
        })}
        prevArrow={
          activeSlide > 0
            ? React.cloneElement(PrevArrow, {
                previous: true
              })
            : null
        }
      >
        {images.map((image, index) => (
          <div key={index}>
            <Image
              image={getImage(image.asset)}
              alt=""
              active={index === activeSlide}
            />
          </div>
        ))}
      </VerticalSlider>
      <MainSlider
        afterChange={(current) => setActiveSlide(current)}
        speed={0}
        swipeToSlide={false}
        asNavFor={nav2}
        ref={(slider) => (sliderRef1 = slider)}
      >
        {images.map((image, index) => (
          <div key={index}>
            <GatsbyImage image={getImage(image.asset)} alt="" />
          </div>
        ))}
      </MainSlider>
    </Container>
  )
}

export default VerticalPreviewCarousel
