import { useState, useEffect, useContext } from "react"
import { BrinkContext } from "../components/context/BrinkContext"
import queryString from "query-string"

const useDiscount = (location) => {
  const [discount, setDiscount] = useState(0)
  const { addDiscount } = useContext(BrinkContext)
  const { discountCode } = queryString.parse(location.search)
  const applyCode = async (discountCode) => {
    await addDiscount({ code: discountCode })
  }

  useEffect(() => {
    if (discountCode) {
      applyCode(discountCode)
      setDiscount(discountCode)
    }
  }, [discountCode])

  return discount
}

export default useDiscount
