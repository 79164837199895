import React, { useLayoutEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import styled, { css } from "styled-components"
import { MEDIA_MIN_LARGE } from "../../../constants"
import { withoutBreaklines } from "../../../helpers/string"
import { toDineroFormatted } from "../../../helpers/toDinero"
import { LocalizedLink } from "../../LocalizedLink"
import { BrinkContext } from "../../context/BrinkContext"
import * as events from "../../context/utils/events"
import { Stars } from "../../product/page/Reviews"
import Backdrop from "../../ui/Backdrop"
import Button from "../../ui/Button"
import Price from "../Price"
import { PortableText } from "@portabletext/react"

const Container = styled.div`
  position: fixed;
  bottom: ${(p) => (p.visible ? "0rem" : "-100rem")};
  right: 0;
  width: 100%;
  z-index: 100;
  background: transparent;
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.4s ease-in;
  padding-inline: 1.2rem;
  padding-bottom: 2.4rem;
  ${MEDIA_MIN_LARGE} {
    padding-inline: 0;
    padding-bottom: 0;
    width: 50%;
    bottom: ${(p) => (p.visible ? "0" : "-100rem")};
    ${(p) =>
      p.popoverVisible
        ? css`
            background-color: white;
          `
        : css`
            background-color: rgba(255, 255, 255, 0.5);
            backdrop-filter: blur(1.7rem);
          `}
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-inline: 1.2rem;
  ${(p) =>
    p.popoverVisible &&
    css`
      background: white;
      border-top: 1px solid ${(p) => p.theme.colors.grey};
    `}
`

const MobileOnly = styled.div`
  display: block;
  width: 100%;
  z-index: 10008;

  ${MEDIA_MIN_LARGE} {
    display: none;
  }
`

const DesktopOnly = styled.div`
  display: none;
  ${MEDIA_MIN_LARGE} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    z-index: 10008;
  }
`

const DisabledButton = styled(Button)`
  background: ${(p) => p.theme.colors.disabledBackground};
  color: ${(p) => p.theme.colors.white};
  cursor: initial;
  width: 100%;
`

const Popover = styled.div`
  position: absolute;
  z-index: 10007;
  transition: all 0.2s ease-in-out;
  bottom: ${(p) => (p.visible ? "calc(4.5rem + 1.2rem)" : "-100rem")};
  right: 0;
  background: white;
  width: calc(100vw - 2.4rem);
  margin-inline: 1.2rem;

  ${MEDIA_MIN_LARGE} {
    bottom: ${(p) => (p.visible ? "4.5rem" : "-100rem")};
    width: 100%;
    margin-inline: 0;
  }
`

const RadioButtonWrapper = styled.div``

const RadioButtonContainer = styled.div`
  width: 100%;
  padding: 1.2rem 1.2rem 1rem;

  ${(p) =>
    p.checked &&
    css`
      border: 0.1rem solid ${(p) => p.theme.colors.black};
    `}

  &:first-of-type {
    border-bottom: 0.1rem solid
      ${(p) => (p.checked ? p.theme.colors.black : p.theme.colors.grey)};
  }

  p,
  ul {
    font-size: 1.6rem;
    line-height: 1.9rem;
    margin-top: 0.6rem;
    margin-bottom: 0;
  }

  del {
    color: ${(p) => p.theme.colors.darkGrey};
  }
`

const NameAndPrice = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 2.4rem;
  height: 2.4rem;
  cursor: pointer;

  > div {
    display: flex;
    align-items: center;

    > label {
      margin-left: 1rem;
      pointer-events: none;
    }
  }
`

const PriceList = styled.div`
  display: flex;
  gap: 1rem;
  text-transform: initial;
`

const FormattedPrice = styled(Price)`
  text-transform: lowercase;
  display: flex;
  justify-content: space-between;

  span {
    font-size: 2rem;
  }
`

const OldPrice = styled(FormattedPrice)`
  color: ${(p) => p.theme.colors.darkGrey};
  text-decoration: line-through;
  text-decoration-thickness: 0.1rem;
`

const Description = styled(PortableText)``

const RadioButton = styled.div`
  height: 1.8rem;
  width: 1.8rem;
  cursor: pointer;
  border-radius: 50%;
  transition: border 0.2s;
  position: relative;
  border: 0.1rem solid ${(p) => p.theme.colors.black};
  margin: 0 1rem 0.1rem 0;

  ${(p) =>
    p.checked &&
    css`
      &::after {
        background: ${(p) => p.theme.colors.black};
        content: "";
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `}
`

const ButtonLink = styled(LocalizedLink)`
  display: inline-block;
  margin: 0;
  text-align: center;
  cursor: pointer;
  background: ${(p) => p.theme.colors.black};
  color: rgb(255, 255, 255);
  padding-inline: 1.2rem;
  padding-block: 1.1rem;
  width: 100%;
  border-radius: 0.3rem;
`

const BottomCheckoutBar = ({
  product,
  variant,
  brinkVariant,
  reviews,
  outOfStock
}) => {
  const { addProductVariantsToCart, languageCode, currentStore } =
    React.useContext(BrinkContext)
  const { t } = useTranslation()
  const [purchaseType, setPurchaseType] = useState(null)
  const [popoverVisible, setPopoverVisible] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  const productName =
    (variant?.displayName[languageCode] ?? variant?.displayName.en)?.replace(
      "\\n",
      " "
    ) ?? ""

  const {
    oneTimePurchaseTitle,
    oneTimePurchaseUsps,
    originalPrice,
    subscriptionEnable,
    subscriptionTitle,
    subscriptionButtonLabel,
    subscriptionInitialFee,
    subscriptionVariant,
    subscriptionSubtitle
  } = variant || {}

  const storeCode = currentStore.countryCode.toLowerCase()

  useLayoutEffect(() => {
    const listener = window.addEventListener("scroll", () => {
      const enterDomElement = document
        .getElementById("product_information")
        ?.getBoundingClientRect()
      const leaveDomElement =
        document.getElementById("related_products")?.getBoundingClientRect() ??
        document.getElementById("footer")?.getBoundingClientRect()

      if (!enterDomElement || !leaveDomElement) return

      const minHeight =
        enterDomElement.top + enterDomElement.height + window.scrollY
      const maxHeight =
        leaveDomElement.top - window.innerHeight + window.scrollY

      if (window.scrollY > minHeight && window.scrollY < maxHeight) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    })
    return () => window.removeEventListener("scroll", listener)
  }, [])

  const togglePurchaseType = (type) => {
    setPurchaseType((t) => (t === type ? null : type))
  }

  const closePopover = () => {
    setPopoverVisible(false)
    setPurchaseType(null)
  }

  const replaceVariables = (rawText) => {
    if (
      !subscriptionInitialFee ||
      !subscriptionVariant?.price ||
      !originalPrice
    ) {
      return rawText
    }
    const subscriptionInitalFeeFormatted = toDineroFormatted(
      parseInt(subscriptionInitialFee[currentStore.currencyUnit]),
      currentStore.currencyUnit
    )
    const subscriptionPriceFormatted = toDineroFormatted(
      parseInt(subscriptionVariant.price[currentStore.currencyUnit]) * 100,
      currentStore.currencyUnit
    )
    const originalPriceFormatted = toDineroFormatted(
      parseInt(originalPrice[currentStore.currencyUnit]) * 100,
      currentStore.currencyUnit
    )
    return rawText.map((block) => {
      if (block._type !== "block") {
        return block
      }
      return {
        ...block,
        children: block.children.map((child) => {
          if (child._type !== "span") {
            return child
          }
          return {
            ...child,
            text: child.text
              .replace(
                "{{subscriptionInitialFee}}",
                subscriptionInitalFeeFormatted
              )
              .replace("{{originalPrice}}", originalPriceFormatted)
              .replace("{{subscriptionPrice}}", subscriptionPriceFormatted)
          }
        })
      }
    })
  }

  if (!variant) return null

  const Buttons = ({ popoverVisible }) => {
    if (outOfStock) {
      return (
        <DisabledButton disabled aria-label="Out of stock">
          {t("Out of stock")}
        </DisabledButton>
      )
    }

    if (purchaseType === "subscription") {
      return (
        <ButtonLink
          onClick={() => {
            events.startSubscriptionPurchase(
              withoutBreaklines(variant.displayName["en"]),
              "bottom checkout bar"
            )
            closePopover()
          }}
          to={`/product/${product.slug.current}/get-started?v=${variant._id}&s=2`}
        >
          {subscriptionButtonLabel[languageCode] || subscriptionButtonLabel.en}
        </ButtonLink>
      )
    }

    if (purchaseType === "oneTime") {
      return (
        <Button
          onClick={() => {
            addProductVariantsToCart(variant._id, 1, true)
            closePopover()
          }}
        >
          {t("Add to cart")}
        </Button>
      )
    }

    if (popoverVisible) {
      return (
        <DisabledButton disabled aria-label="Select an option">
          {t("Select an option")}
        </DisabledButton>
      )
    }
    return (
      <Button
        onClick={() => {
          setPopoverVisible(true)
          events.buttonClicked(
            t("Add to bag"),
            "subscription/one-time selection"
          )
        }}
      >
        {t("Add to bag")}
      </Button>
    )
  }

  if (!brinkVariant) {
    return null
  }

  return (
    <Container visible={isVisible} popoverVisible={popoverVisible}>
      <DesktopOnly>
        <Row popoverVisible={popoverVisible}>
          <span>{productName}</span>
          {reviews?.count > 0 && (
            <Stars stars={Math.round(reviews?.average)} count={reviews.count} />
          )}
        </Row>
        <Buttons popoverVisible={popoverVisible} />
      </DesktopOnly>
      <MobileOnly>
        <Buttons popoverVisible={popoverVisible} />
      </MobileOnly>
      <Popover visible={isVisible && popoverVisible}>
        <RadioButtonWrapper>
          {subscriptionEnable?.[storeCode] && (
            <RadioButtonContainer checked={purchaseType === "subscription"}>
              <NameAndPrice
                onClick={() => {
                  togglePurchaseType("subscription")
                }}
              >
                <div>
                  <RadioButton checked={purchaseType === "subscription"} />
                  {subscriptionTitle[languageCode] || subscriptionTitle.en}
                </div>
                <PriceList>
                  <OldPrice
                    price={brinkVariant.price}
                    allDiscount={
                      brinkVariant.discount && brinkVariant.discount.length > 0
                        ? brinkVariant.discount
                        : null
                    }
                  />
                  <FormattedPrice
                    price={subscriptionInitialFee ?? subscriptionVariant.price}
                    multiply={!subscriptionInitialFee}
                  />
                </PriceList>
              </NameAndPrice>
              <Description
                value={replaceVariables(
                  subscriptionSubtitle?.[languageCode]?._rawText ?? []
                )}
              />
            </RadioButtonContainer>
          )}
          <RadioButtonContainer checked={purchaseType === "oneTime"}>
            <NameAndPrice
              onClick={() => {
                togglePurchaseType("oneTime")
              }}
            >
              <div>
                <RadioButton checked={purchaseType === "oneTime"} />
                {oneTimePurchaseTitle[languageCode] || oneTimePurchaseTitle.en}
              </div>
              <FormattedPrice
                price={brinkVariant.price}
                allDiscount={
                  brinkVariant.discount && brinkVariant.discount.length > 0
                    ? brinkVariant.discount
                    : null
                }
              />
            </NameAndPrice>
          </RadioButtonContainer>
        </RadioButtonWrapper>
      </Popover>
      {popoverVisible && (
        <Backdrop active={isVisible && popoverVisible} onClick={closePopover} />
      )}
    </Container>
  )
}

export default BottomCheckoutBar
