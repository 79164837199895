import { PortableText } from "@portabletext/react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import styled, { css } from "styled-components"
import { MEDIA_MIN_LARGE, MEDIA_MIN_MEDIUM } from "../../../../constants"
import { withoutBreaklines } from "../../../../helpers/string"
import { toDineroFormatted } from "../../../../helpers/toDinero"
import { LocalizedLink } from "../../../LocalizedLink"
import { BrinkContext } from "../../../context/BrinkContext"
import * as events from "../../../context/utils/events"
import Button from "../../../ui/Button"
import Price from "../../Price"

const AddToCartButton = styled(Button)`
  z-index: 10;
  justify-content: center;
  border-radius: 0.3rem;
  margin: 0;
  height: 4.5rem;
  white-space: pre-wrap;
  width: 100%;
  position: sticky;
  top: 7.4rem;
  ${MEDIA_MIN_LARGE} {
    top: 0;
  }

  & > div {
    font-variation-settings: "wght" 450, "wdth" 700;
  }

  &:hover {
    > div {
      font-variation-settings: "wght" 700, "wdth" 700;
    }
  }
`

const DisabledButton = styled(Button)`
  background: ${(p) => p.theme.colors.disabledBackground};
  color: ${(p) => p.theme.colors.white};
  cursor: initial;
  width: 100%;
`

const ButtonText = styled.div`
  font-size: 2rem;
  line-height: 2rem;
  margin-right: 0.5rem;
  display: flex;

  ${MEDIA_MIN_MEDIUM} {
    margin: 0;
  }
`

const FormattedPrice = styled(Price)`
  display: flex;
  justify-content: space-between;

  span {
    font-size: 2rem;
  }
`

const RadioButtonWrapper = styled.div`
  margin-bottom: 2.4rem;
`

const RadioButtonContainer = styled.div`
  width: 100%;
  padding: 1.2rem;
  border: 0.1rem solid
    ${(p) => (p.checked ? p.theme.colors.black : p.theme.colors.grey)};

  p,
  ul {
    font-size: 1.6rem;
    line-height: 1.9rem;
    margin-top: 0.6rem;
    margin-bottom: 0;
  }

  del {
    color: ${(p) => p.theme.colors.darkGrey};
  }
`

const NameAndPrice = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 2.4rem;
  cursor: pointer;

  > div {
    display: flex;
    align-items: center;

    > label {
      margin-left: 1rem;
      pointer-events: none;
    }
  }
`

const PriceList = styled.div`
  display: flex;
  gap: 1rem;
  text-transform: initial;
`

const OldPrice = styled(FormattedPrice)`
  color: ${(p) => p.theme.colors.darkGrey};
  text-decoration: line-through;
  text-decoration-thickness: 0.1rem;
`

const Description = styled(PortableText)``

const RadioButton = styled.div`
  height: 1.8rem;
  width: 1.8rem;
  cursor: pointer;
  border-radius: 50%;
  transition: border 0.2s;
  position: relative;
  border: 0.1rem solid ${(p) => p.theme.colors.black};
  margin: 0 1rem 0.1rem 0;

  ${(p) =>
    p.checked &&
    css`
      &::after {
        background: ${(p) => p.theme.colors.black};
        content: "";
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `}
`

const Actions = ({
  product,
  currentVariant,
  brinkVariant,
  productOutOfStock
}) => {
  const { addProductVariantsToCart, languageCode, currentStore } =
    useContext(BrinkContext)
  const { t } = useTranslation("translations")
  const [purchaseType, setPurchaseType] = useState("oneTime")

  const comingSoon = currentVariant?.comingSoon || false

  const {
    oneTimePurchaseTitle,
    oneTimePurchaseUsps,
    originalPrice,
    subscriptionEnable,
    subscriptionTitle,
    subscriptionButtonLabel,
    subscriptionInitialFee,
    subscriptionVariant,
    subscriptionSubtitle
  } = currentVariant || {}

  const storeCode = currentStore.countryCode.toLowerCase()

  useEffect(() => {
    if (subscriptionEnable?.[storeCode]) {
      setPurchaseType("subscription")
    }
  }, [])

  if (!brinkVariant) {
    return null
  }

  const onSubscriptionButtonClicked = () => {
    events.startSubscriptionPurchase(
      withoutBreaklines(currentVariant.displayName.en),
      "product page"
    )
  }

  const replaceVariables = (rawText) => {
    if (
      !subscriptionInitialFee ||
      !subscriptionVariant?.price ||
      !originalPrice
    ) {
      return rawText
    }
    const subscriptionInitalFeeFormatted = toDineroFormatted(
      parseInt(subscriptionInitialFee[currentStore.currencyUnit]),
      currentStore.currencyUnit
    )
    const subscriptionPriceFormatted = toDineroFormatted(
      parseInt(subscriptionVariant.price[currentStore.currencyUnit]) * 100,
      currentStore.currencyUnit
    )
    const originalPriceFormatted = toDineroFormatted(
      parseInt(originalPrice[currentStore.currencyUnit]) * 100,
      currentStore.currencyUnit
    )
    return rawText.map((block) => {
      if (block._type !== "block") {
        return block
      }
      return {
        ...block,
        children: block.children.map((child) => {
          if (child._type !== "span") {
            return child
          }
          return {
            ...child,
            text: child.text
              .replace(
                "{{subscriptionInitialFee}}",
                subscriptionInitalFeeFormatted
              )
              .replace("{{originalPrice}}", originalPriceFormatted)
              .replace("{{subscriptionPrice}}", subscriptionPriceFormatted)
          }
        })
      }
    })
  }

  return (
    <>
      {!currentVariant ? (
        <DisabledButton disabled aria-label="Please select tone first">
          {t("Please select tone first")}
        </DisabledButton>
      ) : productOutOfStock ? (
        <DisabledButton disabled aria-label="Out of stock">
          {t("Out of stock")}
        </DisabledButton>
      ) : comingSoon ? (
        <DisabledButton disabled aria-label="Coming soon">
          {t("Coming soon")}
        </DisabledButton>
      ) : subscriptionEnable?.[storeCode] ? (
        <>
          <RadioButtonWrapper>
            {subscriptionEnable?.[storeCode] && (
              <RadioButtonContainer checked={purchaseType === "subscription"}>
                <NameAndPrice
                  onClick={() => {
                    setPurchaseType("subscription")
                  }}
                >
                  <div>
                    <RadioButton checked={purchaseType === "subscription"} />
                    {subscriptionTitle[languageCode] || subscriptionTitle.en}
                  </div>
                  <PriceList>
                    <OldPrice
                      price={brinkVariant.price}
                      allDiscount={
                        brinkVariant.discount &&
                        brinkVariant.discount.length > 0
                          ? brinkVariant.discount
                          : null
                      }
                    />
                    <FormattedPrice
                      price={
                        subscriptionInitialFee ?? subscriptionVariant.price
                      }
                      multiply={!subscriptionInitialFee}
                    />
                  </PriceList>
                </NameAndPrice>
                <Description
                  value={replaceVariables(
                    subscriptionSubtitle?.[languageCode]?._rawText ?? []
                  )}
                />
              </RadioButtonContainer>
            )}
            <RadioButtonContainer checked={purchaseType === "oneTime"}>
              <NameAndPrice
                onClick={() => {
                  setPurchaseType("oneTime")
                }}
              >
                <div>
                  <RadioButton checked={purchaseType === "oneTime"} />
                  {oneTimePurchaseTitle[languageCode] ||
                    oneTimePurchaseTitle.en}
                </div>
                <FormattedPrice
                  price={brinkVariant.price}
                  allDiscount={
                    brinkVariant.discount && brinkVariant.discount.length > 0
                      ? brinkVariant.discount
                      : null
                  }
                />
              </NameAndPrice>
            </RadioButtonContainer>
          </RadioButtonWrapper>
          {purchaseType === "oneTime" && (
            <AddToCartButton
              aria-label={t("Add to bag")}
              onClick={() => {
                addProductVariantsToCart(currentVariant._id, 1, true)
              }}
            >
              <ButtonText>
                {t("Add to bag")}{" "}
                <FormattedPrice
                  price={brinkVariant.price}
                  allDiscount={
                    brinkVariant.discount && brinkVariant.discount.length > 0
                      ? brinkVariant.discount
                      : null
                  }
                />
              </ButtonText>
            </AddToCartButton>
          )}
          {purchaseType === "subscription" && (
            <LocalizedLink
              onClick={onSubscriptionButtonClicked}
              to={`/product/${product.slug.current}/get-started/?v=${currentVariant._id}&s=2`}
            >
              <AddToCartButton
                aria-label={
                  subscriptionButtonLabel?.[languageCode] ?? t("Get started")
                }
              >
                <ButtonText>
                  {subscriptionButtonLabel?.[languageCode] ?? t("Get started")}
                </ButtonText>
              </AddToCartButton>
            </LocalizedLink>
          )}
        </>
      ) : (
        <>
          <AddToCartButton
            aria-label={t("Add to bag")}
            onClick={() => {
              addProductVariantsToCart(currentVariant._id, 1, true)
            }}
          >
            <ButtonText>
              {t("Add to bag")}{" "}
              <FormattedPrice
                price={brinkVariant.price}
                allDiscount={
                  brinkVariant.discount && brinkVariant.discount.length > 0
                    ? brinkVariant.discount
                    : null
                }
              />
            </ButtonText>
          </AddToCartButton>
        </>
      )}
    </>
  )
}

export default Actions
