import React, { useContext } from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BrinkContext } from "../context/BrinkContext"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;
  margin-bottom: 2.4rem;
`

const ImageWrapper = styled.div`
  border: ${(props) => (props.selected ? "1px solid black" : "none")};
`

const Image = styled(GatsbyImage)`
  height: 6rem;
  width: 6rem;
  cursor: pointer;
`

const VariantSelector = ({ variants, selectedVariant, onVariantSelected }) => {
  const { languageCode } = useContext(BrinkContext)
  return (
    <Container>
      {variants.map((variant) => (
        <ImageWrapper
          key={variant._id}
          selected={selectedVariant?._id === variant._id}
          onClick={() => onVariantSelected(variant)}
        >
          <Image
            image={getImage(
              variant.optionImage?.asset ?? variant.mainImage.asset
            )}
            alt={variant.displayName[languageCode]}
            selected={selectedVariant?._id === variant._id}
          />
        </ImageWrapper>
      ))}
    </Container>
  )
}

export default VariantSelector
