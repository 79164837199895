import { useEffect, useState } from 'react';
import axios from 'axios';

const useReviews = (productId) => {
  const [reviews, setReviews] = useState({
    average: 0,
    count: 0
  });
  useEffect(() => {
    axios
      .get("https://api.reviews.io/product/review", {
        params: {
          store: "comis.com",
          apikey: process.env.GATSBY_REVIEWS_IO_API_KEY ?? "",
          sku: productId,
          per_page: 1,
          page: 1
        }
      })
      .then((response) => {
        if (response?.data?.stats) {
          setReviews(response.data.stats) // average + count
        }
      })
      .catch((error) => {
        console.error("There was an error!", error)
      })
  }, [productId]);
  return reviews;
}

export default useReviews;
