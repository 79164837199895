import { useEffect, useState } from "react"
import { useContext } from "react"
import { BrinkContext } from "../components/context/BrinkContext"

const useStocks = (variantId) => {
  const [productOutOfStock, setProductOutOfStock] = useState(false)
  const { getStocks } = useContext(BrinkContext)

  useEffect(() => {
    if (variantId) {
      getStocks([variantId])
        .then((products) => {
          if (products.length) {
            setProductOutOfStock(products[0].availableQuantity < 1)
          } else {
            setProductOutOfStock(false)
          }
        })
        .catch((error) => {
          console.error("Error getting stocks", error)
        })
    }
  }, [variantId])
  return productOutOfStock
}

export default useStocks
