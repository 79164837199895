import loadable from "@loadable/component"
import { PortableText } from "@portabletext/react"
import { Script, graphql } from "gatsby"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import Layout from "../components/Layout"
import { BrinkContext } from "../components/context/BrinkContext"
import * as events from "../components/context/utils/events"
import Popup from "../components/popup/Popup"
import VariantGrid from "../components/product/VariantGrid"
import VariantSelector from "../components/product/VariantSelector"
import BottomCheckoutBar from "../components/product/page/BottomCheckoutBar"
import ImageGallery from "../components/product/page/ImageGallery"
import { Stars } from "../components/product/page/Reviews"
import Actions from "../components/product/page/actions/Actions"
import TextWithLineBreaks from "../components/ui/TextWithLineBreaks"
import VerticalPreviewCarousel from "../components/ui/VerticalPreviewCarousel"
import Usp from "../components/widgets/Usp"
import WidgetLoader from "../components/widgets/WidgetLoader"
import { MEDIA_MIN_LARGE, MEDIA_MIN_MEDIUM } from "../constants"
import { scrollTo } from "../helpers/scrollTo"
import { withoutBreaklines } from "../helpers/string"
import { toDinero } from "../helpers/toDinero"
import { updateSearchParams } from "../helpers/url"
import useDiscount from "../hooks/useDiscount"
import useReviews from "../hooks/useReviews"
import useSanityTranslation from "../hooks/useSanityTranslation"
import useSelectedVariant from "../hooks/useSelectedVariant"
import useStocks from "../hooks/useStocks"

const ProductSlider = loadable(() =>
  import("../components/widgets/ProductSlider")
)

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: auto;
`

const MobileImageGallery = styled(ImageGallery)`
  grid-area: 1 / 1 / 1 / 17;

  ${MEDIA_MIN_LARGE} {
    display: none;
  }
`

const Media = styled.div`
  display: none;

  ${MEDIA_MIN_LARGE} {
    display: block;
    grid-area: 1 / 1 / 1 / 9;
  }
`

const Information = styled.div`
  grid-area: 2 / 1 / 2 / 17;
  padding-inline: 1.2rem;
  margin-top: 1.2rem;
  ${MEDIA_MIN_MEDIUM} {
    grid-area: 2 / 1 / 2 / 12;
  }
  ${MEDIA_MIN_LARGE} {
    margin-top: 0;
    grid-area: 1 / 10 / 1 / 15;
    padding-inline: 0;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

const UspContainer = styled.div`
  margin-top: 1.2rem;
`

const NameWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background: ${(p) => p.theme.colors.background};

  h3 {
    font-size: 2rem;
    text-transform: initial;
    margin: 0;
  }

  ${MEDIA_MIN_LARGE} {
    position: sticky;
    z-index: 2;
  }
`
const Name = styled.h1`
  font-size: 2.6rem;
  line-height: 3rem;
  text-transform: initial;
  font-variation-settings: "wght" 500;
  text-align: left;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.725rem;
`

const GuidelineIcon = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid ${(p) => p.theme.colors.black};
  font-size: 1.2rem;
`

const RatingContainer = styled.div`
  cursor: pointer;
`

const MatchingProducts = styled(ProductSlider)`
  padding: 2rem 2.5rem;

  h2 {
    text-align: center;
  }

  ${MEDIA_MIN_MEDIUM} {
    padding: 2rem 0;
  }
`
const WidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5.4rem;
  margin-top: 5.4rem;
  margin-bottom: 16.5rem;
`

const StyledPopup = styled(Popup)`
  background-color: white;
  ${MEDIA_MIN_LARGE} {
    max-width: 36rem;
    min-height: 45rem;
  }
`
const PopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: self-start;
  height: 6rem;
  padding: 1.2rem 1.2rem 0;

  h3 {
    font-size: 2rem;
    margin: 0 !important;
  }
`
const PopupText = styled.div`
  padding: 1.2rem;
  line-height: 2.3rem !important;
  font-size: 2rem;
`

const ProductPage = ({
  data: { sanityProduct, brinkcommerce },
  pageContext,
  location
}) => {
  const { t } = useTranslation("translations")
  const trans = useSanityTranslation()
  const { languageCode, currentStore, setListName, listName } =
    useContext(BrinkContext)
  useDiscount(location)
  const [openGuidelinePopup, setOpenGuidelinePopup] = useState(false)

  const { currencyUnit } = currentStore
  const { clickProduct } = location.state || {}

  const {
    variants,
    displayName,
    matchingProducts,
    relatedProducts,
    usp,
    category,
    badge,
    title,
    videos,
    metaTitle,
    metaDescription
  } = sanityProduct
  const { badge: categoryBadge } = category

  // @TODO: add active check before release
  const getActiveVariants = (variants) => {
    return variants.filter(
      (v) => v.active && !v.title.includes("[SUBSCRIPTION]")
    )
  }

  const activeVariants = useMemo(() => getActiveVariants(variants), [variants])
  const [sanityProductVariant, setSanityProductVariant] = useSelectedVariant(
    activeVariants,
    location
  )
  const brinkVariants = brinkcommerce.getProduct.relatedVariants
  const brinkVariant =
    brinkVariants.find((v) => v.id === sanityProductVariant?._id) ??
    brinkVariants[0]
  const productOutOfStock = useStocks(sanityProductVariant?._id)

  const relatedVariants = useMemo(
    () => getActiveVariants(relatedProducts?.[0]?.variants ?? []),
    [variants]
  )

  const price = brinkVariant?.price.find(
    (p) => p.currencyUnit === currencyUnit
  ).amount
  const reviewStats = useReviews(sanityProductVariant?._id)

  useEffect(() => {
    if (clickProduct && clickProduct.listName) {
      events.clickProduct({
        product: sanityProduct,
        index: clickProduct.positionInList,
        listName: clickProduct.listName,
        positionInList: clickProduct.positionInList,
        currentStore
      })
      setListName(clickProduct.listName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickProduct, setListName])

  useEffect(() => {
    if (!brinkVariant) return null
    events.viewProduct({
      sanityProductVariant,
      sanityProduct,
      languageCode,
      prices: brinkVariant.price,
      discount: brinkVariant.discount,
      currentStore,
      listName: listName || null,
      index: clickProduct?.positionInList ?? null
    })
  }, [
    languageCode,
    sanityProductVariant,
    sanityProduct,
    brinkVariant,
    currentStore,
    title,
    listName,
    clickProduct
  ])

  const onReviewClick = () => {
    setTimeout(() => {
      scrollTo(".reviews", 100)
    }, 500)
    events.productReviewsAnchorClicked(brinkVariant.id, brinkVariant.name)
  }

  const showGuidelinePopup = () => {
    setOpenGuidelinePopup(true)
  }

  if (!sanityProductVariant || !brinkVariant || !price) return null

  return (
    <Layout
      meta={{
        title: metaTitle?.[languageCode] || metaTitle?.en,
        description: metaDescription?.[languageCode] || metaDescription?.en
      }}
      invertedHeader
      pageContext={pageContext}
      slug={`product/${sanityProduct.slug.current}`}
    >
      <Script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org/",
          "@type": "Product",
          "name": "${displayName[languageCode] || displayName.en} ${
          sanityProductVariant.strength
        }",
          "image": [
            "${sanityProductVariant.mainImage.asset.url}"
          ],
          "description": "${
            sanityProductVariant.shortDescription[languageCode] ||
            sanityProductVariant.shortDescription.en
          }",
          "sku": "${sanityProductVariant.customerAttribute_productFeed_SKU}",
          "mpn": "${sanityProductVariant.customerAttribute_productFeed_mpn}",
          "brand": {
            "@type": "Brand",
            "name": "COMIS"
          },
          "offers": {
            "@type": "Offer",
            "url": "${location.href}",
            "priceCurrency": "${currencyUnit}",
            "price": "${toDinero(price, currencyUnit).toFormat("0,0")}",
            "availability": "https://schema.org/InStock"
          }
        }
      `}
      </Script>

      <Grid id="product_information">
        <MobileImageGallery
          name={displayName.en}
          mainImage={sanityProductVariant.mainImage}
          images={sanityProductVariant.images}
          currentVariant={sanityProductVariant}
          languageCode={languageCode}
          badge={badge?.en && badge}
          categoryBadge={categoryBadge?.en && categoryBadge}
          videos={videos}
        />
        <Media>
          <VerticalPreviewCarousel
            images={[
              sanityProductVariant.mainImage,
              ...sanityProductVariant.images
            ]}
          />
        </Media>
        <Information>
          <NameWrapper>
            <Name>
              <TextWithLineBreaks
                text={
                  sanityProductVariant.displayName[languageCode] ||
                  sanityProductVariant.displayName.en
                }
              />
            </Name>{" "}
            {reviewStats !== null ? (
              <RatingContainer onClick={onReviewClick}>
                <Stars
                  stars={Math.round(reviewStats.average)}
                  count={reviewStats.count}
                />
              </RatingContainer>
            ) : null}
          </NameWrapper>
          {sanityProductVariant.displaySubName && (
            <NameWrapper>
              <Name>
                {trans(sanityProductVariant.displaySubName)}
                {sanityProductVariant.guidelines && (
                  <GuidelineIcon onClick={showGuidelinePopup}>?</GuidelineIcon>
                )}
              </Name>
              {sanityProductVariant.capacity && (
                <Name>{trans(sanityProductVariant.capacity)}</Name>
              )}
            </NameWrapper>
          )}
          {activeVariants.length > 1 ? (
            <VariantSelector
              variants={activeVariants}
              selectedVariant={sanityProductVariant}
              onVariantSelected={(variant) => {
                setSanityProductVariant(variant)
                updateSearchParams(location, "variant", variant.slug.current)
                events.capsuleSelected(
                  withoutBreaklines(variant.displayName.en)
                )
              }}
            />
          ) : null}
          <PortableText
            value={
              sanityProductVariant.formattedShortDescription?.[languageCode]
                ?._rawText ?? ""
            }
          />
          <Actions
            product={sanityProduct}
            brinkVariant={brinkVariant}
            currentVariant={sanityProductVariant}
            productOutOfStock={productOutOfStock}
          />
          <UspContainer>
            <Usp entries={usp?.uspEntries} display="chip" />
          </UspContainer>
        </Information>
      </Grid>
      {sanityProductVariant.widgets?.length > 0 && (
        <WidgetContainer>
          <WidgetLoader
            widgets={sanityProductVariant.widgets}
            variant={sanityProductVariant}
          />
        </WidgetContainer>
      )}
      {matchingProducts && matchingProducts.length > 0 && (
        <MatchingProducts
          products={matchingProducts}
          title={t("Matching products")}
          columns="4"
        />
      )}
      {relatedProducts && relatedProducts.length > 0 && (
        <div id="related_products">
          <VariantGrid
            title={t("related-variants-title")}
            product={relatedProducts[0]}
            variants={relatedVariants.slice(0, 4)}
            columns="4"
          />
        </div>
      )}
      <BottomCheckoutBar
        product={sanityProduct}
        variant={sanityProductVariant}
        brinkVariant={brinkVariant}
        reviews={reviewStats}
        outOfStock={productOutOfStock}
      ></BottomCheckoutBar>
      <StyledPopup
        openPopup={openGuidelinePopup}
        setOpenPopup={setOpenGuidelinePopup}
        closeText={`X ${t("Close")}`}
        smallPopup
      >
        <PopupHeader>
          <h3>{t("guidelines")}</h3>
        </PopupHeader>
        <PopupText>
          <PortableText
            value={trans(sanityProductVariant.guidelines)?._rawText}
          />
        </PopupText>
      </StyledPopup>
    </Layout>
  )
}

export default ProductPage

export const query = graphql`
  query ($sanityProductId: String!, $brinkProductId: ID!) {
    sanityProduct(_id: { eq: $sanityProductId }) {
      ...Product
    }
    brinkcommerce {
      getProduct(id: $brinkProductId) {
        relatedVariants {
          id
          name
          slug
          price {
            amount
            currencyUnit
          }
          discount {
            amount
            currencyUnit
          }
        }
      }
    }
  }
`
