import React from "react"
import styled from "styled-components"
import {
  MEDIA_MAX_SMALL,
  MEDIA_MIN_LARGE,
  MEDIA_MIN_MEDIUM
} from "../../constants"
import Backdrop from "../ui/Backdrop"
import Logo from "../../images/comis-logo.svg"

const Wrapper = styled.div`
  position: absolute;
  z-index: 10006;
  background: ${(p) => p.theme.colors.background};
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  border-radius: 0.2rem;
  cursor: initial;
  padding-bottom: env(safe-area-inset-bottom);

  ${MEDIA_MIN_MEDIUM} {
    top: 50%;
    left: 50%;
    right: auto;
    transform: translate(-50%, -50%);
  }

  ${MEDIA_MIN_LARGE} {
    bottom: initial;
    padding-bottom: 0;
  }
`

const Container = styled.div`
  color: ${(p) => p.theme.colors.black};
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  ${MEDIA_MIN_MEDIUM} {
    height: inherit;
    max-height: 80vh;
  }

  h1,
  h2,
  h3 {
    text-align: left !important;
  }

  h1 {
    font-variation-settings: "wght" 400 !important;
  }

  strong {
    font-weight: 700;
    letter-spacing: 0;
  }

  p,
  li {
    font-family: "Helvetica Neue Light Condensed";
    font-size: 1.6rem;
    line-height: 1.85rem;
    font-weight: 300;
  }

  ul {
    padding-left: 2rem;
  }
`

const Blur = styled.div`
  height: 15rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
`

const CloseButton = styled.button`
  border: none;
  background: none;
  font-size: 2rem;
  padding: 1rem;
  cursor: pointer;
  color: ${(p) => p.theme.colors.black};
  transition: all 0.2s;
  text-transform: initial;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: calc(7.4rem / 4);

  ${MEDIA_MIN_MEDIUM} {
    top: 0;
  }

  &:hover {
    font-variation-settings: "wght" 750;
  }
`

const Heading = styled.div`
  display: block;
  height: 7.4rem;
  background: ${(p) => p.theme.colors.background};

  svg {
    height: 5rem;
    width: 9.3rem;
    margin: 1.2rem;
    fill: ${(p) => p.theme.colors.black};
  }

  ${MEDIA_MIN_MEDIUM} {
    display: none;
  }
`

const Popup = ({
  children,
  openPopup,
  setOpenPopup,
  closeText,
  smallPopup,
  ...props
}) => {
  if (!children) {
    return false
  }

  return (
    <Backdrop active={openPopup} handleOnClick={() => setOpenPopup(false)}>
      {openPopup && (
        <Wrapper
          onClick={(e) => e.stopPropagation()}
          {...props}
          smallPopup={smallPopup}
        >
          {!smallPopup ? (
            <Heading>
              <Logo alt="COMIS logo" />
            </Heading>
          ) : null}
          <Container smallPopup={smallPopup}>
            {children}
            {closeText && (
              <CloseButton onClick={() => setOpenPopup(false)}>
                {closeText}
              </CloseButton>
            )}
          </Container>
        </Wrapper>
      )}
    </Backdrop>
  )
}

export default Popup
